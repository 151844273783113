(function(w,d,$){
  'use strict';

  //svgスプライト読み込み
  $.loadSVG = function(option){
    var opt = $.extend(true,{
                svg:'/assets/svg/sprite.symbol.svg',
                $target:'body',
                callback:function(svg){
                  var div = d.createElement("div");
                  div.innerHTML = new XMLSerializer().serializeToString(svg.documentElement);
                  d.body.insertBefore(div, d.body.childNodes[0]);
                  loadSVG.resolve(svg);
                }
              },option),
        loadSVG = new $.Deferred;
    $.get(opt.svg).done(opt.callback);
    return loadSVG;//promise
  };

}(window,document,jQuery));