(function(w, d, $) {
  "use strict";
  $.fn.toggleMenu = function (option) {
    var default_opt = $.extend(true, {
      target: "#gMenuHeader",
      method: "toggleClass",
      param: "show",
      btn_active: "active"
    }, option);
    return this.each(function () {
      var btn = $(this),
        opt = $.extend(true, {}, default_opt, btn.data()),
        target = $(opt.target);
      btn.on("click", function () {
        return target[opt.method](opt.param), btn.toggleClass(opt.btn_active), !1
      }), $(d).on("click touchend", function (e) {
        !btn.hasClass(opt.btn_active) || $(e.target).closest(target).length || $(e.target).closest(btn).length || (btn.removeClass(opt.btn_active), target[opt.method](opt.param))
      })
    })
  }
})(window, document, jQuery);