(function(w,d,$){
  'use strict';

  $.fn.accordion = function(option){

    var g_opt = $.extend(true,{
            trigger: this,
            target: this.next(),
            speed: 200
        },option);
    
    return this.each(function(){
      var $this = $(this),
          opt = $.extend(true,{},g_opt,{
            trigger: $this,
            target: $this.next()
          }, $this.data() );
      if(typeof(opt.target)=='string') opt.target = $(opt.target);
      if(opt.trigger.hasClass('open')){
        opt.target.addClass('open');
      }else{
        opt.target.removeClass('open').hide();
      }
      opt.trigger.on('click',function(){
        opt.target.css({overflow:'hidden'}).slideToggle(opt.speed,function(){
          $(this).css({overflow:''});
        });
        $(this).toggleClass('open');
        opt.target.toggleClass('open');
      });
    });
  };


}(window,document,jQuery));